import axios from "axios";

export const api = axios.create({
    withCredentials: true
})

export const handleLogin = formData => {
    return new Promise((resolve, reject) => {
        api.post('/login', formData)
            .then(response => {
                if(!response || !response.data.access){
                    reject('Hiba történt a bejelentkezés során!')
                }

                if(response.data.error){
                    reject('Hozzáférés megtagadva!')
                }

                resolve(response.data.access)
            })
            .catch(err => {
                reject("Megszakadt a kapcsolat a szerverrel!")
            })
    })
}

export const handleLogout = () => {
    return new Promise((resolve, reject) => {
        api.post('/logout')
            .then(result => {
                if(!result || result.data.error){
                    reject('Hiba történt a kijelentkezés során!')
                }

                resolve(result.data.status)
            })
            .catch(err => {
                reject('Megszakadt a kapcsolat a szerverrel!')
            })
    })
}

export const checkSession = () => {
    return new Promise((resolve, reject) => {
        api.post('/auth')
            .then(response => {
                if(!response){
                    reject('Hiba történt a feldolgozás során!')
                }

                if(response.data.error){
                    reject('Hozzáférés megtagadva!')
                }

                resolve(response.data.auth)
            })
            .catch(err => {
                reject("Megszakadt a kapcsolat a szerverrel!")
            })
    })
}

export const uploadImages = files => {
    return new Promise((resolve, reject) => {
        api.post('/admin/upload', files, { headers: { 'Content-Type': `multipart/form-data; boundary=${files._boundary}` } })
            .then(response => {
                if(!response){
                    reject('Hiba történt a feldolgozás során!')
                }

                if(response.data.error){
                    reject('Hiba történt a feltöltés során!')
                }

                if(response.data.done){
                    resolve(true)
                }
            })
            .catch(err => {
                reject("Megszakadt a kapcsolat a szerverrel!")
            })
    })
}

export const getPaths = () => {
    return new Promise((resolve, reject) => {
        api.post('/admin/paths')
            .then(result => {

                if(result && result.data.error){
                    reject(result.data.error)
                }

                if(result && result.data.images){
                    resolve(result.data.images)
                }else {
                    resolve([])
                }
            })
            .catch(err => {
                reject("Megszakadt a kapcsolat a szerverrel!")
            })
    })
}

export const getImages = () => {
    return new Promise((resolve, reject) => {
        api.post('/pictures')
            .then(result => {
                if(result && result.data.error){
                    reject('Hiba történt a képek lekérése során!')
                }

                if(result && result.data.images){
                    resolve(result.data.images)
                }
            })
            .catch(err => {
                reject("Megszakadt a kapcsolat a szerverrel!")
            })
    })
}

export const deleteImgs = path_array => {
    return new Promise((resolve, reject) => {
        api.post('/admin/delete', { paths: path_array })
            .then(res => {
                resolve(res.data.done)
            })
            .catch(err => {
                reject('Megszakadt a kapcsolat a szerverrel!')
            })
    })
}
import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Loading from '../../components/backdrop';
import { ThemeProvider, createTheme, Paper } from '@mui/material';
import { checkSession, handleLogin } from '../../api';
import Notify from '../../components/notify';

const theme = createTheme({
    palette: {
        mode: 'dark',
        color: 'white'
    },
})

export default function Login() {

    const [state, setState] = useState({ username: '', password: '', loading: true, showNotify: false, notifySeverity: 'error', notifyMsg: '' })
    const navigate = useNavigate()

    useEffect(() => {
        checkSession()
            .then(res => {
                if(res){
                    navigate('/admin')
                }
                setState(data => ({ ...data, loading: false }))
            })
            .catch(err => {
                setState(data => ({...data,  loading: false, showNotify: true, notifySeverity: 'error', notifyMsg: err }))
            })
    }, [navigate])
    

    const handleChange = useCallback(e => {
        setState( data =>  ({ ...data, [e.target.name] : e.target.value }))
    }, [])

    const handleSubmit = useCallback(e => {
        e.preventDefault()
        if(!state.username || !state.password){
            setState(data => ({ ...data, showNotify: true, notifySeverity: 'error', notifyMsg: 'A mezők kitöltése kötelező!' }))
            return 
        }

        setState(data => ({ ...data, loading: true }))
        handleLogin(state)
            .then(response => {
                if(response){
                    navigate('/admin')
                }
                setState(data => ({ ...data, loading: false }))
            })
            .catch(err => {
                setState(data => ({...data, loading: false, showNotify: true, notifySeverity: 'error', notifyMsg: err }))
            })
    }, [state, navigate])

    return (
        <ThemeProvider theme={theme}>
            <Loading load={state.loading} />
            <Notify show={state.showNotify} message={state.notifyMsg} setParentState={setState} severity={state.notifySeverity} />
            <Grid container
                justifyContent='center'
                sx={{ height: '100vh',alignItems:'center' }}>

                <Grid item md={6}>
                    <Paper variant="outlined" square sx={{ height: '50%', backgroundColor: 'transparent' }}>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'red', opacity: '75%', color:'white' }}>
                                <LockOutlinedIcon />
                            </Avatar>

                            <Typography variant="h4">
                                Bejelentkezés
                            </Typography>
                            <Box component="form"
                                onSubmit={handleSubmit}
                                sx={{ mt: 1 }}
                            >
                                <TextField
                                    required
                                    fullWidth
                                    id="username"
                                    label="Felhasználónév"
                                    name="username"
                                    type='text'
                                    autoComplete="off"
                                    autoFocus
                                    onChange={handleChange}
                                    value={state.username}
                                />
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Jelszó"
                                    type="password"
                                    id="password"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    value={state.password}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ ':hover': { bgcolor: 'black' }, mt: 3, mb: 2, backgroundColor: 'red', opacity: '75%', color: 'white' }}
                                >
                                    Bejelentkezés
                                </Button>

                            </Box>
                        </Box>
                    </Paper>
                </Grid>

            </Grid>
        </ThemeProvider>
    )
};
import Gallery from "react-grid-gallery";
import { Images } from "./photos";
import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { getImages } from "../../api";
import Loading from "../backdrop";

function WorksGallery() {

  const [state, setState] = useState({ images: null, loading: false })

  useEffect(() => {
    getImages()
      .then(res => {
        setState(state => ({ ...state, images: res, loading: false }))
      })
      .catch(err => {
        setState(state => ({ ...state, loading: false }))
      })
  }, [])

  return (
    <motion.div>
      <Loading load={state.loading} />
      <Grid sx={{mt:10}}>
        <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
        >
        <Grid container alignItems="center" justifyContent="center">
          <h1>Munkáink</h1>
        </Grid>
        <hr />
        <Grid container alignItems="center" justifyContent="center" sx={{ textAlign: 'center' }}>
          <h3>A további képek megtekintésért kattints valamelyik képre és lapozd végig galériánkat!</h3>
        </Grid>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <Gallery
            images={Images(state.images)}
            enableLightbox
            backdropClosesModal
            enableImageSelection={false}
            maxRows={2}
          />
        </motion.div>
      </Grid>
    </motion.div>
  );
}
export default WorksGallery;
import NavBar from "./components/navbar";
import Login from './routes/login';
import BaseRoute from './routes/base'
//import UploadGallery from './routes/upload-photos'; //   <Route exact path="gallery" element={<UploadGallery />}></Route>
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminPage from "./routes/upload-photos";

import "./style/custom.css"

function App() {
  return (
    <div className="App">
      <NavBar/>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<BaseRoute />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/admin" element={<AdminPage />} />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;

import { Grid } from "@mui/material";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { motion } from "framer-motion";

function Contacts() {
    return (
        <motion.div>
            <Grid>

                <Grid container alignItems="center" justifyContent="center">
                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                    >
                        <h1>Elérhetőségek</h1>
                    </motion.div>
                </Grid>
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                ><hr /></motion.div>

                <Grid>

                    <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        direction='row'
                        sx={{ mb: 5, mt: 5 }}
                        spacing={1}
                    >

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={3}
                            sx={{ textAlign: 'center' }}
                        >
                            <motion.div
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5, delay: 0.8 }}
                            >
                                <MailIcon sx={{ fontSize: 60 }} />
                                <br />
                                <a href="mailto:nyiregyhaz10@hotmail.com">nyiregyhaz10@hotmail.com</a>
                            </motion.div>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={3}
                            sx={{ textAlign: 'center' }}
                        >

                            <motion.div
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5, delay: 0.4 }}
                            >
                                <PhoneAndroidIcon sx={{ fontSize: 60 }} />
                                <br />
                                <div>+36302745418</div>
                            </motion.div>
                        </Grid>


                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={3}
                            sx={{ textAlign: 'center' }}
                        >
                            <motion.div
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5, delay: 0.4 }}
                            >
                                <FacebookIcon sx={{ fontSize: 60 }} />
                                <br />
                                <a href="https://www.facebook.com/matabebutor">Matabe bútor</a>
                            </motion.div>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={3}
                            sx={{ textAlign: 'center' }}
                        >
                            <motion.div
                                initial={{ opacity: 0, y: 100 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5, delay: 0.8 }}
                            >
                                <LocationOnIcon sx={{ fontSize: 60 }} />
                                <br />
                                <a href="https://www.google.com/maps/place/%C3%93p%C3%A1lyi,+%C3%81rp%C3%A1d+u.+104,+4821/@47.9830342,22.3184298,17z/data=!3m1!4b1!4m5!3m4!1s0x4738634e358b7957:0x1baf89a767d59b78!8m2!3d47.9830306!4d22.3206185">Ópályi, Árpád utca 104 Hrsz.: 384</a>
                            </motion.div>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </motion.div>
    )
}
export default Contacts;
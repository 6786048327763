import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import NavItems from './nav-items';
import Logo from '../../style/images/logo.jpg';
import Grid from '@mui/material/Grid';

const drawerWidth = '100%';
export const navItems = [{ name: 'Kezdőlap', href: '#home' }, { name: 'Rólunk', href: '#about' }, { name: 'Munkáink', href: '#gallery' }, { name: 'Elérhetőségek', href: '#contacts' }];

function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box 
      onClick={handleDrawerToggle} 
      sx={{ textAlign: 'center', width: 'auto' }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
      <img id="logo" src={Logo} width="45px" height="19px" alt='logo' />Matabe Bútor
      </Typography>
      <Divider sx={{backgroundColor:'red', width: '100%'}}/>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center'}}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" sx={{backgroundColor:"black", borderBottom:1,borderColor:"red"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Grid 
          sx={{ display:{xs:'none',sm:'block'}}}>
          <img id="logo" src={Logo} width="72px" height="40px" alt='logo' />
          </Grid>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, m:1, display: { xs: 'none', sm: 'block'} }}
          >
            Matabe bútor
          </Typography>
        <NavItems navItems={navItems}/>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor:'black',
              color:'white'
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main">
        
        <Toolbar />
      </Box>
    </Box>
  );
}

export default NavBar;
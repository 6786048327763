import { React } from 'react';
import { Card, Grid } from '@mui/material';
import { CardMedia } from '@mui/material';
import { motion } from "framer-motion";

function Map() {
    return (

        <Grid sx={{ width: "100%" }}>
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.8 }}
            >
                <iframe src="https://maps.google.com/maps?q=%C3%93p%C3%A1lyi,%20%C3%81rp%C3%A1d%20utca%20104&t=&z=13&ie=UTF8&iwloc=&output=embed" width="100%" frameborder="0"></iframe>
            </motion.div>
        </Grid>

    );
}
export default Map;


import { Box,Button } from "@mui/material"

export default function NavItems({ navItems }){
    return <Box sx={{ display: { xs: 'none', sm: 'block', backgroundColor:'black'} }}>
    {navItems.map((item, index) => (
      <a key={index} href={item.href}>
        <Button key={item.name} sx={{ color: 'white' }}>
        {item.name}
      </Button></a>
    ))}
  </Box>
}
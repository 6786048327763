import ConstructionIcon from '@mui/icons-material/Construction';
import ChairIcon from '@mui/icons-material/Chair';
import { Grid } from "@mui/material";
import { motion } from "framer-motion";

function Features() {
    return (
        <motion.div>
            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                sx={{ mb: 3, mt: 3, textAlign: 'center' }}
            >
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.2 }}>
                    <h1>Amiért megéri minket választani</h1>
                </motion.div>
            </Grid>

            <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}>
                <hr />
            </motion.div>

            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                sx={{ mt: 5, textAlign: 'center' }}
            >

                <Grid item md={4} lg={6}>
                    <div>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: 0.5 }}

                        >
                            <ConstructionIcon sx={{ fontSize: 70, mb: 1, color: 'white' }} />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: 0.5 }}>
                            <h3>Precíz, pontos munkavégzés</h3>
                        </motion.div>
                    </div>
                </Grid>


                <Grid item md={4} lg={6}>
                    <div>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                        >
                            <ChairIcon sx={{ fontSize: 70, mb: 1, color: 'white' }} />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: 0.5 }}>
                            <h3>Egyedi, személyre szabott darabok készítése</h3>
                        </motion.div>
                    </div>
                </Grid>
            </Grid>
        </motion.div>
    )
}
export default Features;
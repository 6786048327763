import Intro from "./intro";
import Features from "./features";
import { Grid } from "@mui/material";

function Introduction() {
    return (
        <Grid>
        <Intro></Intro>
        <Features></Features>
        </Grid>
    )
}
export default Introduction;
import { MotionPhotosAuto } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import Map from './map';
import Motto from './motto'


function Footer() {
    return(
    <Grid container>
            <Map></Map>
            <Motto></Motto>
    </Grid>
    )
}
export default Footer;
import { Grid, Paper, styled } from "@mui/material";
import pic from '../../style/images/store2.jpg'
import { motion } from "framer-motion";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#1A2027',
    textAlign: 'center',
    color: 'white',
    fontSize: 25,
}));

function Intro() {
    return (
        <Grid id='header'>
            <Grid container alignItems="center" justifyContent="center">
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.8 }}
                >
                    <h1>Rólunk</h1>
                </motion.div>
            </Grid>
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.8 }}
            ><hr />
            </motion.div>
            <Grid container alignItems="center" direction="row" justifyContent="center" mt={5}>
                <Grid item lg={6}>
                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: 1 }}
                    ><Item elevation={0}><img width='100%' height='auto' alt="Bútorbolt" src={pic} /></Item>
                    </motion.div>
                </Grid>

                <Grid item lg={4} >
                    <Item elevation={0}>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: 0.8 }}
                        >
                            <b>
                                Mi itt a Matabe bútornál több mint 20 éves szakmai tapasztalattal rendelkezünk.
                                Fő profilunk az egyedi bútorok és beltéri nyílászárók gyártása, amit vagy saját felmérés és tervezés alapján,
                                vagy lakberendezők tervei alapján készítünk el.
                            </b>
                            </motion.div>
                    </Item>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Intro;
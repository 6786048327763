//import { motion } from "framer-motion"
import { Grid } from "@mui/material";

function Home() {
    return (
        <Grid container justifyContent='center' className="header">
            <h1>Matabe bútor</h1>
            <h2>...mert a minőség számít</h2>
        </Grid>

    )
}
export default Home;
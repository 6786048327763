import pic1 from '../../style/images/1.jpg';
import pic2 from '../../style/images/2.jpg';
import pic3 from '../../style/images/3.jpg';
import pic4 from '../../style/images/4.jpg';
import pic5 from '../../style/images/5.jpg';
import pic6 from '../../style/images/6.jpg';
import pic7 from '../../style/images/7.jpg';
import pic8 from '../../style/images/8.jpg';
import pic9 from '../../style/images/9.jpg';
import pic10 from '../../style/images/10.jpg';
import pic11 from '../../style/images/11.jpg';
import pic12 from '../../style/images/12.jpg';

const pics = [ pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9, pic10, pic11, pic12 ]

export const Images = (data = pics) => {

  const iterable = data ? data : pics

  return iterable.map(src => {

    return {
      src: src,
      thumbnail:
        src,
      thumbnailWidth: 320,
      thumbnailHeight: 174,
    }
  }) 

}
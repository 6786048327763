import ChairRoundedIcon from '@mui/icons-material/ChairRounded';
import Grid from '@mui/material/Grid';
import { motion } from "framer-motion";

function Motto() {
    return (

        <Grid container direction="row" justifyContent="center">
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.8 }}
            >
                <ChairRoundedIcon sx={{ mt: 0.5 }} />
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.8 }}
            >
                <Grid sx={{ mt: 1 }}>Matabe...mert a minőség számít!</Grid>
            </motion.div>
        </Grid>

    )
}
export default Motto;
import Footer from "../../components/footer";
import WorksGallery from "../../components/gallery";
import Introduction from "../../components/introduction";
import Home from "../../components/header";
import Contacts from "../../components/contacts";
import { Fragment } from "react";

export default function BaseRoute() {
  return (
    <Fragment>
      <div id="home"><Home /></div>
      <div id="about"><Introduction /></div>
      <div id="gallery"><WorksGallery /></div>
      <div id="contacts"><Contacts/></div>
      <Footer />
    </Fragment>
  )
}
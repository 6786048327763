import { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageList from './list';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/backdrop';

import { checkSession, deleteImgs, handleLogout, uploadImages, getPaths } from '../../api';
import Notify from '../../components/notify';

export default function AdminPage() {

    const navigate = useNavigate()

    const [state, setState] = useState({ loading: true, showNotify: false, notifySeverity: 'error', notifyMsg: '', checked: [], list: [] })

    useEffect(() => {
        checkSession()
            .then(res => {
                if (!res) {
                    navigate('/login', { replace: true })
                }

                getPaths()
                    .then(res => {
                        setState(st => ({ ...st, loading: false, list: res }))
                    })
                    .catch(err => {
                        setState(st => ({ ...st, loading: false, showNotify: true, notifySeverity: 'error', notifyMsg: err }))
                    })
            })
            .catch(err => {
                setState(data => ({ ...data, loading: false }))
                navigate('/login', { replace: true })
            })

    }, [navigate])

    const logout = () => {
        setState(data => ({ ...data, loading: true }))
        handleLogout()
            .then(res => {
                if (res) {
                    navigate('/login')
                }
                setState(data => ({ ...data, loading: false }))
            })
            .catch(err => {
                navigate('/login')
                setState(data => ({ ...data, loading: false, showNotify: true, notifyMsg: err, notifySeverity: 'error' }))
            })
    }

    const handleChange = useCallback(e => {
        const data = new FormData()
        const images = Array.prototype.slice.call(e.target.files)

        if(images.length > 10){
            setState(data => ({ ...data, showNotify: true, notifyMsg: 'Egyszerre maximum 10 képet tölthet fel!', notifySeverity: 'error' }))
            return
        }

        setState(data => ({ ...data, loading: true }))
        images.forEach(image => {
            data.append('images', image)
        })

        uploadImages(data)
            .then(res => {
                setState(data => ({ ...data, loading: false, showNotify: true, notifyMsg: res ? 'A képek feltöltése sikeres!' : 'A képek feltöltése sikertelen', notifySeverity: res ? 'success' : 'error' }))

            }).catch(err => {
                setState(data => ({ ...data, loading: false, showNotify: true, notifyMsg: err, notifySeverity: 'error' }))
            })
    }, [])

    const delItems = useCallback(e => {
        if (state.checked.length !== 0) {
            setState(st => ({ ...st, loading: true }))
            deleteImgs(state.checked)
                .then(res => {
                    setState(st => ({ ...st, loading: false, showNotify: true, notifyMsg: 'A kijelölt elemek törlésre kerültek!', notifySeverity : 'success' }))
                    setTimeout(() => window.location.reload(), 1000)
                })
                .catch(err => {
                    setState(data => ({ ...data, loading: false, showNotify: true, notifyMsg: err, notifySeverity: 'error' }))
                })
        }
    }, [state])

    return (
        <Grid>
            <Loading load={state.loading} />
            <Notify show={state.showNotify} message={state.notifyMsg} setParentState={setState} severity={state.notifySeverity} />
            <Button component="label" sx={{ backgroundColor: 'red', color: 'white', ':hover': { bgcolor: 'black' }, mt: 3, ml: 3 }}  onClick={logout}>Kijelentkezés</Button>
            <Grid container justifyContent="center" sx={{ color: 'white', mb: 2, mx: 1.5 }}>
                <h1>Admin felület</h1>
            </Grid>
            <hr />
            <Grid container justifyContent="center">
                <Grid container justifyContent="center" sx={{ width: '100%', mx: 2 }}>
                    <ImageList setParentState={setState} parentState={state} />
                </Grid>
                <Grid container direction="row" justifyContent="center">
                    <Grid padding={2}>
                        <Button component="label" sx={{ backgroundColor: 'red', color: 'white', ':hover': { bgcolor: 'black' } }}>
                            Feltöltés
                            <input hidden accept="image/*" multiple type="file" onChange={handleChange} />
                        </Button>
                        <IconButton aria-label="upload picture" component="label">
                            <input hidden accept="image/*" type="file" />
                            <PhotoCamera sx={{ color: 'red' }} />
                        </IconButton>
                    </Grid>
                    <Grid padding={2}>
                        <Button component="label" sx={{ backgroundColor: 'red', color: 'white', ':hover': { bgcolor: 'black' } }} onClick={delItems}>
                            Kijelölt elemek törlése
                        </Button>
                        <IconButton aria-label="upload picture" component="label" >
                            <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Paper } from '@mui/material';
import { red } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: red[200],
        },
    }
}
);

export default function ImageList({ setParentState, parentState }) {

    const handleToggle = (value) => () => {
        const currentIndex = parentState.checked.findIndex(el => el.id === value.id)
        const newChecked = [...parentState.checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setParentState(state => ({ ...state, checked: newChecked }));
    };

    return (
        <List dense sx={{ width: '50%', maxHeight: 500, overflow: 'auto' }}>
            <Paper padding={2} variant="outlined" square sx={{ color: 'white', backgroundColor: 'transparent' }}>
                {parentState.list && parentState.list.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value.id}`;
                    return (
                        <ListItem sx={{ borderBottom: 1, borderColor: 'red' }}
                            key={value.id}
                            secondaryAction={
                                <ThemeProvider theme={theme}>
                                    <Checkbox
                                        edge="end"
                                        onChange={handleToggle(value)}
                                        checked={parentState.checked.findIndex(el => el.id === value.id) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        sx={{ color: 'red' }}
                                    />
                                </ThemeProvider>
                            }
                            disablePadding
                        >
                            <ListItemButton>
                                <ListItemText id={labelId} primary={value.name} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </Paper>
        </List>

    );
}